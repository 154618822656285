import React from 'react'
import { graphql } from 'gatsby'
import insert from 'lodash/fp/add'

// Components
import Layout from 'components/layout'
import SEO from 'components/seo'
import { Blocks } from 'components/Blocks'

// Types
import {
  Block,
  CaseBlockTypes,
  CaseSummary,
  ContentfulCase,
} from 'types/contentful'

interface Props {
  data: {
    case: ContentfulCase
  }
  pageContext: {
    slug: string
  }
}

const SingleCaseTemplate: React.FC<Props> = ({ data }) => {
  const {
    case: {
      title,
      excerpt,
      client,
      tools,
      tasks,
      methods,
      projectDate,
      blocks,
    },
  } = data

  if (!blocks) {
    return null
  }

  const [first, ...rest] = blocks

  const summaryBlock: CaseSummary = {
    __typename: CaseBlockTypes.CaseSummary,
    id: 'CaseBlockSummary',
    client,
    date: projectDate,
    methods,
    tasks,
    tools,
  }

  const newBlocks: Block[] = [first, summaryBlock, ...rest]

  return (
    <Layout theme="Dark">
      <SEO title={title} description={excerpt.excerpt} />
      <div className="single-case">
        {newBlocks && <Blocks blocks={newBlocks} />}
      </div>
    </Layout>
  )
}

export default SingleCaseTemplate

export const query = graphql`
  query SingleCase($slug: String!) {
    case: contentfulCase(slug: { eq: $slug }) {
      title
      excerpt {
        excerpt
      }
      featuredMedia {
        fluid(maxWidth: 448) {
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
      client
      tools
      tasks
      methods
      projectDate
      blocks {
        ... on ContentfulBlock {
          ...BlocksFragment
        }

        ... on ContentfulBlockDivider {
          __typename
        }

        ... on ContentfulBlockProjectTeam {
          __typename
          id
          title
          team {
            raw
          }
        }

        ... on ContentfulBlockTextBoxes {
          __typename
          id
          title
          textBoxes {
            body {
              raw
            }
            title
            backgroundColor
          }
        }

        ... on ContentfulCaseImageGallery {
          __typename
          id
          imageLeftGallery {
            gatsbyImageData(placeholder: BLURRED, quality: 80)
          }
          imageGalleryRightTop {
            gatsbyImageData(placeholder: BLURRED, quality: 80)
          }
          imageGalleryRightBottom {
            gatsbyImageData(placeholder: BLURRED, quality: 80)
          }
        }

        ... on ContentfulCaseImageLeftDoubleTextfields {
          __typename
          id
          topContent {
            raw
          }
          bottomContent {
            raw
          }
          imageLeft {
            gatsbyImageData(placeholder: BLURRED, quality: 80)
          }
        }

        ... on ContentfulCaseImageRightSingleTextfield {
          __typename
          id
          imageSingleTextContent {
            raw
          }
          imageRight {
            gatsbyImageData(placeholder: BLURRED, quality: 80)
          }
        }

        ... on ContentfulCaseImageFullscreen {
          __typename
          id
          imageFullscreen {
            gatsbyImageData(
              placeholder: BLURRED
              formats: [AUTO, WEBP]
              layout: FULL_WIDTH
              height: 500
              quality: 80
            )
          }
        }

        ... on ContentfulCaseHero {
          __typename
          id
          fullScreenHero
          client
          areaOfWork
          heroImage {
            gatsbyImageData(
              placeholder: BLURRED
              formats: [AUTO]
              layout: FULL_WIDTH
              quality: 90
            )
          }
          body {
            raw
          }
        }
        ... on ContentfulCaseNormalText {
          id
          __typename
          content {
            raw
          }
        }
        ... on ContentfulBlockKeyFigures {
          __typename
          id
          contentKeyFigures {
            ... on ContentfulBlockElements {
              id
              title
              body {
                raw
              }
            }
          }
        }
        ... on ContentfulCaseAbout {
          id
          __typename
          background
          references {
            ... on ContentfulBlockElements {
              title
              body {
                raw
              }
            }
          }
        }
        ... on ContentfulHtmlCode {
          __typename
          id
          name
          code {
            code
          }
        }
      }
    }
  }
`
